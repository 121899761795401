import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import DownloadProp from '../components/DownloadProp';

const PedirBotellon = () => (
    <Layout>
        <SEO title="Pedir Botellón - Aguaman" description="" pathname="/pedir-botellon" />
        <DownloadProp />
    </Layout>
);

export default PedirBotellon;
