import React from 'react';

const DownloadProp = props => {
    return (
        <section className="download-prop">
            <div className="download-prop-content">
                <div className="main-message">
                    <h1>Pide tu botellón cómo quieras</h1>
                    <p className="download-subtitle">
                        Descarga la app, regístrate y <strong>acumula puntos</strong> con tus
                        pedidos para canjear recargas gratis.
                    </p>

                    <div className="links-content">
                        <div>
                            <a
                                className="button button-download"
                                href="https://play.google.com/store/apps/details?id=com.aguaman.app"
                            >
                                <div className="logo-google-play"></div>&nbsp; Google Play
                            </a>
                        </div>
                        <div>
                            <a
                                className="button button-download"
                                href="https://apps.apple.com/kz/app/aguaman/id1541778190"
                            >
                                <div className="logo-app-store"></div>&nbsp; App store
                            </a>
                        </div>
                    </div>
                    <br />
                    <p className="download-subtitle">
                        Si las aplicaciones no son lo tuyo, escribe a nuestro Whatsapp y te
                        atenderemos con gusto.
                    </p>
                    <a
                        style={{ marginTop: 8 }}
                        className="button button-success-outlined"
                        href="https://wa.me/584247710732?text=Hola%20aguaman.%20Quiero%20recargar%20mi%20botellón%20de%20agua."
                    >
                        Whatsapp
                    </a>
                </div>
                <div className="main-photo-download"></div>
            </div>
        </section>
    );
};

export default DownloadProp;
